import axios from "axios";
import { API_URL } from "./config.js";

export const apiCall = (url, method, data) => {
    return axios({
        method: method,
        url: url,
        data: data,
    })
        .then((response) => {
            return { "code": response.status, "data": response.data };
        })
        .catch((error) => {
            return { "code": 0, "data": error.response.data };
        });
};



//login api
export const loginApi = (formData) => apiCall(`${API_URL}login`, "POST", formData);

//resetpassword
export const resetpassword = (formData) => apiCall(`${API_URL}resetpassword`, "POST", formData);


//get card for dashboard
export const getcard = () => apiCall(`${API_URL}getCard`, "GET", []);

export const dashboarddata = () => apiCall(`${API_URL}dashboarddata`, "GET", []);

export const getproductlist = () => apiCall(`${API_URL}getproductlist`, "GET", []);

//get latest user with page, sizePerPage, filters, sortField, sortOrder
// export const getlatestUser = (page, sizePerPage, filters, sortField, sortOrder, searchText,recurring_status) => apiCall(`${API_URL}getLatestUser?page=${page}&sizePerPage=${sizePerPage}&filters=${filters}&sortField=${sortField}&sortOrder=${sortOrder}&searchText=${searchText}&recurring_status=${recurring_status}`, "GET", []);
export const getlatestUser = (page, sizePerPage, filters, sortField, sortOrder, searchText,recurring_status) => apiCall(`${API_URL}getLatestUser?page=${page}&sizePerPage=${sizePerPage}&filters=${filters}&sortField=${sortField}&sortOrder=${sortOrder}&searchText=${searchText}`, "GET", []);

//get getnotificationapi
export const getnotificationapi = () => apiCall(`${API_URL}getnotifications`, "GET", []);

//get getallnotificationapi
export const getallnotificationapi = () => apiCall(`${API_URL}getallnotifications`, "GET", []);

//get getnotificationapi
export const updatebookingapi = (formData) => apiCall(`${API_URL}updatebooking`, "POST", formData);

//updateallbookingapi
export const updateallbookingapi = () => apiCall(`${API_URL}updateallbooking`, "GET", []);

export const crmpreference = (page, sizePerPage, filters, sortField, sortOrder, searchText,table) => apiCall(`${API_URL}addcrmpreference?page=${page}&sizePerPage=${sizePerPage}&filters=${filters}&sortField=${sortField}&sortOrder=${sortOrder}&searchText=${searchText}&table=${table}`, "GET", []);

export const getcrmpreference = (table) => apiCall(`${API_URL}getcrmpreference?table=${table}`, "GET", []);


export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/";
}

export const register = (username, email, password) => {
    return apiCall(`${API_URL}/register`, "POST", {
        username,
        email,
        password
    }).then((response) => {
        if (response.code === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
    });
}

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));;
}

export const isAuthenticated = () => {
    const user = getCurrentUser();
    if (user) {
        return true;
    }
    return false;
}







