import React, { useState, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { TbListCheck } from "react-icons/tb";
import { BookingContext } from "./BookingContext";
import { LoginContext } from "../../LoginContext";
import { Modal } from "react-bootstrap";
import { usePaymentInputs } from 'react-payment-inputs';
import { getpaymentMethod } from "../../Api/dashboardApi";
import { createPaymentMethod } from "../../Api/dashboardApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';






const Step4 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const { getExpiryDateProps } = usePaymentInputs();

    const [cardNumber, setCardNumber] = useState('');
    const [cardExpDate, setCardExpDate] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [fullName, setFullName] = useState('');
    const [addCardValidate, setAddCardValidate] = useState(false)

    const [addCard, setAddCard] = useState(false);

    const [selectedCard, setSelectedCard] = useState('');
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [cardExpYearerror, setCardExpYearerror] = useState(false);


    const [cardNumbererror, setCardNumbererror] = useState(false);
    const [cardCVVerror, setCardCVVerror] = useState(false);
    const [cardExpDateerror, setCardExpDateerror] = useState(false);
    const [fullNamerror, setFullNamerror] = useState(false);

    const getpaymentCards = () => {
        const LoggedInData = JSON.parse(localStorage.getItem('userdata'));
        const formData = new FormData();

        if (LoggedInData && LoggedInData.id) {
            formData.append('customer_id', LoggedInData.customer_id);
        }
        getpaymentMethod(formData).then((res) => {
            if (res.code == 200) {
                const paymentMethod = res.data.data;
                setPaymentMethod(paymentMethod);
                if (paymentMethod && paymentMethod.length != 0 && !selectedCard) {
                    setSelectedCard(`cardOption0`);
                    setPaymentDetails(paymentMethod[0]);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }



    React.useEffect(() => {
        getpaymentCards();
    }, []);


    React.useEffect(() => {
        getpaymentCards();
    }, [LoggedInData]);

    // const handleAddCard = () => {

    //     //check if name like sanjay kumar
    //     const name = fullName.split(' ');
    //     if (name.length < 2) {
    //         alert("Please enter full name");
    //         return;
    //     }

    //     const expDateParts = cardExpDate.split('/');

    //     const expMonth = parseInt(expDateParts[0]);
    //     const expYear = parseInt(expDateParts[1]);

    //     const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1
    //     const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year

    //     if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
    //         // alert("Card expiration date should be in the future.");
    //         setCardExpYearerror(true);
    //         return;
    //     }

    //     if (cardNumber == '' || cardNumber.length != 16 || fullName == '' || cardCVV == '' || cardCVV.length != 3 || cardExpDate == '') {
    //         setAddCardValidate(true)
    //     } else {
    //         const formData = new FormData();
    //         if (LoggedInData && LoggedInData.id) {
    //             formData.append('customer_id', LoggedInData.customer_id);
    //         }
    //         formData.append("card_number", cardNumber);
    //         formData.append("person_name", fullName);
    //         formData.append("card_cvv", cardCVV);
    //         formData.append("card_exp", cardExpDate);

    //         createPaymentMethod(formData).then((res) => {
    //             if (res.code === 200) {
    //                 alert("Card Added Successfully");
    //                 getpaymentCards();
    //                 setAddCard(false);
    //             }
    //             else {
    //                 alert("Something went wrong");
    //             }
    //         }).catch((err) => {
    //             console.log("err", err);
    //         });
    //     }
    // }

    const handleAddCard = () => {
        // if (cardNumber == '' || cardNumber.length != 20 || fullName == '' || cardCVV == '' || cardCVV.length != 3 || cardExpDate == '') {
        //     setAddCardValidate(true)
        // } else {

        if (cardNumber == '' || cardNumber.length != 19) {
            setCardNumbererror(true);
            return;
        } else {
            setCardNumbererror(false);
        }

        if (cardCVV == '' || cardCVV.length != 3) {
            setCardCVVerror(true);
            return;
        } else {
            setCardCVVerror(false);
        }

        if (cardExpDate == '') {
            setCardExpDateerror(true);
            return;
        }

        if (fullName == '') {
            setFullNamerror(true);
            return;
        } else {
            setFullNamerror(false);
        }

        const expDateParts = cardExpDate.split('/');

        const expMonth = parseInt(expDateParts[0]);
        const expYear = parseInt(expDateParts[1]);

        const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1
        const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year

        if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
            // alert("Card expiration date should be in the future.");
            setCardExpYearerror(true);
            return;
        } else {
            setCardExpYearerror(false);
        }

        const name = fullName.split(' ');
        if (name.length < 2) {
            setFullNamerror(true);
            return;
        } else {
            setFullNamerror(false);
        }



        const formData = new FormData();
        if (LoggedInData && LoggedInData.id) {
            formData.append('customer_id', LoggedInData.customer_id);
        }
        formData.append("card_number", cardNumber);
        formData.append("person_name", fullName);
        formData.append("card_cvv", cardCVV);
        formData.append("card_exp", cardExpDate);

        createPaymentMethod(formData).then((res) => {
            if (res.code === 200) {
                console.log("res", res.data);
                if (res.data.status === "1") {
                    NotificationManager.success("Card Added Successfully");
                    getpaymentCards();
                    setAddCard(false);
                } else {
                    const errorMessage = res.data.message;
                    NotificationManager.error(errorMessage);
                }
            }
            else {
                NotificationManager.error("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        });
        // }
    }

    React.useEffect(() => {
        if (LoggedInData != null) {
            setFullName(LoggedInData.first_name + " " + LoggedInData.last_name);
        }
    }, [LoggedInData]);

    React.useEffect(() => {
        if (LoggedInData == null) {
            setPaymentMethod([]);
        }
    }, [LoggedInData]);

    const onCardNumberChange = (event) => {
        let { value, name } = event.target;
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
    };

    if (props.currentStep !== 4) {
        return null;
    }

    return (
        <>
            <Modal show={addCard} onHide={() => setAddCard(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Card Number</Form.Label>
                                <Form.Control
                                    required
                                    // type="number"
                                    // placeholder="*** *** **** 9856"
                                    // className='custom-input'
                                    // value={cardNumber}
                                    // onInput={(e) => {
                                    //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16)

                                    // }}
                                    // onChange={(e) => { setCardNumber(e.target.value); }} />
                                    type="tel"
                                    name="cardNumber"
                                    className="custom-input"
                                    autoComplete="off"
                                    onChange={onCardNumberChange}
                                    maxLength="19"
                                    // onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                                    // onBlur={onCardInputBlur}
                                    value={cardNumber} />
                                {cardNumbererror == true ? <p style={{ color: 'red' }}>card number is required</p> : null}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input'
                                    value={cardExpDate}
                                    {...getExpiryDateProps({ onChange: (e) => setCardExpDate(e.target.value) })} />
                                {cardExpDateerror == true ? <p style={{ color: 'red' }}>please enter card exipry date</p> : ''}
                                {cardExpYearerror == true ? <p style={{ color: 'red' }}>Card expiration date should be in the future.</p> : ''}
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>CVV</Form.Label>
                                <Form.Control required type="number" placeholder="CVV" className='custom-input'
                                    value={cardCVV}
                                    onInput={
                                        (e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                        }
                                    }
                                    onChange={(e) => setCardCVV(e.target.value)} />
                                {cardCVVerror == '' ? <p style={{ color: 'red' }}>please enter cvv</p> : ''}
                                {cardCVV.length != 3 ? <p style={{ color: 'red' }}>cvv must have 3 digits</p> : ''}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)} />
                                {fullNamerror == true ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' type="submit" onClick={handleAddCard} >Save</button>
                            <button href="#" className='btn Cancel-bttn' onClick={() => { setAddCard(false) }}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="step-count d-flex align-items-center justify-content-between" >
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    Fattmerchant Payment
                                </div>
                            </div>
                            <div className="add-card-button">
                                <button type="button" className="wizard-btn btn btn-primary" onClick={() => setAddCard(true)}>Add Card</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 booking-30">
                        <div className="booking-acount-detail-form" style={{ display: usertype === 1 ? 'block' : 'none' }}>
                            {
                                paymentMethod && paymentMethod.length != 0 && paymentMethod.map((item, index) => {
                                    const cardOptionId = `cardOption${index}`;
                                    const isFirstCard = index === 0;
                                    const isChecked = selectedCard === cardOptionId || (isFirstCard && !selectedCard);
                                    return (
                                        <div class="form-check mb-3">
                                            <div className="d-flex align-items-center">
                                                <input
                                                    className="form-check-input me-3"
                                                    type="radio"
                                                    name="cardOption"
                                                    id={`cardOption${index}`}
                                                    value={`cardOption${index}`}
                                                    checked={isChecked}
                                                    defaultChecked={index === 0}
                                                    onChange={
                                                        (e) => {
                                                            setAddCard(false);
                                                            setSelectedCard(e.target.value);
                                                            setPaymentDetails(item);
                                                        }
                                                    }
                                                />
                                                {/* <input class="form-check-input me-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" /> */}
                                                <label class="form-check-label payment-card-details" for="flexRadioDefault1">
                                                    <div className="card payment-card-details">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <div className="card-title h5">****-****-****-{
                                                                        item.card_last_four || " "
                                                                    }</div>
                                                                    <p className="card-text">{
                                                                        item.nickname || " "
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                    );
                                })
                            }


                            {/* <div class="form-check mb-3">
                                <div className="d-flex align-items-center">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="cardOption"
                                        id="cardOption2"
                                        value="cardOption2"
                                        checked={selectedCard === 'cardOption2'}
                                        onChange={
                                            (e) => {
                                                setAddCard(false);
                                                setSelectedCard(e.target.value);
                                            }
                                        }
                                    />
                                    <label class="form-check-label payment-card-details" for="flexRadioDefault3">
                                        <div className="card payment-card-details">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div>
                                                        <div className="card-title h5">****-****-****-1234</div>
                                                        <p className="card-text">Card Name</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    );
};

export default Step4;
