import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FormControl } from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import BootstrapTable from 'react-bootstrap-table-next';
import Form from "react-bootstrap/Form";
import pagination from './pagination';
import SearchBox from './SearchBox';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import './table.css';
import { useContext } from "react";
import { ModalPopContext } from "../../Technician/ModalPopContext";
import { deleteTechnicianOffTime } from "../../Api/technician";
import { addTechnicianOffTime } from "../../Api/technician";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getCurrentTimeByTimeZone } from "../../helper/timezone";

const Informasi = () => {

    const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear, technicianofftimerequest, setTechnicianofftimerequest] = useContext(ModalPopContext);
 
    const [addBreakRequest, setAddBreakRequest] = useState('');
 
    // const data = technicianOfftime && technicianOfftime.map((item) => {
    //     const start_date = item.start_date_time.split(" ");
    //     const end_date = item.end_date_time.split(" ");

    //     const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //     const start_date_split = start_date[0].split("-");
    //     const end_date_split = end_date[0].split("-");
    //     start_date[0] = monthNames[start_date_split[1] - 1] + "-" + start_date_split[2] + "-" + start_date_split[0];
    //     end_date[0] = monthNames[end_date_split[1] - 1] + "-" + end_date_split[2] + "-" + end_date_split[0];

    //     const start_time = start_date[1].split(":");
    //     const end_time = end_date[1].split(":");
    //     if (start_time[0] > 12) {
    //         start_time[0] = start_time[0] - 12;
    //         start_date[1] = start_time[0] + ":" + start_time[1] + " PM";
    //     } else {
    //         start_date[1] = start_time[0] + ":" + start_time[1] + " AM";
    //     }
    //     if (end_time[0] > 12) {
    //         end_time[0] = end_time[0] - 12;
    //         end_date[1] = end_time[0] + ":" + end_time[1] + " PM";
    //     } else {
    //         end_date[1] = end_time[0] + ":" + end_time[1] + " AM";
    //     }

    //     const formatted_start_date = monthNames[start_date_split[1] - 1] + "-" + start_date_split[2] + "-" + start_date_split[0];
    //     const formatted_end_date = monthNames[end_date_split[1] - 1] + "-" + end_date_split[2] + "-" + end_date_split[0];

    //     return {
    //         id: item.id,
    //         startdate: start_date[0],
    //         formatted_startdate: formatted_start_date,
    //         starttime: start_date[1],
    //         enddate: end_date[0],
    //         formatted_enddate: formatted_end_date, // Formatted date for display
    //         endtime: end_date[1],
    //         off_time_note: item.off_time_note,
    //         status : "APPROVED",
    //     }
    // })

    const data = technicianOfftime && technicianOfftime.map((item) => {
        const start_date = item.start_date_time.split(" ");
        const end_date = item.end_date_time.split(" ");

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const start_date_split = start_date[0].split("-");
        const end_date_split = end_date[0].split("-");
        const formatted_start_date = monthNames[start_date_split[1] - 1] + "-" + start_date_split[2] + "-" + start_date_split[0];
        const formatted_end_date = monthNames[end_date_split[1] - 1] + "-" + end_date_split[2] + "-" + end_date_split[0];

        // Convert time to 12-hour format
        const formatTime = (time) => {
            const [hour, minute] = time.split(":");
            return `${hour % 12 || 12}:${minute} ${hour >= 12 ? 'PM' : 'AM'}`;
        };

        return {
            id: item.id,
            startdate: start_date[0], // Original date for sorting
            formatted_startdate: formatted_start_date, // Formatted date for display
            starttime: formatTime(start_date[1]),
            enddate: end_date[0], // Original date for sorting
            formatted_enddate: formatted_end_date, // Formatted date for display
            endtime: formatTime(end_date[1]),
            off_time_note: item.off_time_note,
            status: "APPROVED",
        }
    })

    useEffect(() => {
    }, [technicianOfftime]);

    const addBreakTechnician = () => {
        if (start === "" || end === "") {
            NotificationManager.error("Please select start and end time");
            return;
        }
        if (addBreakRequest === "") {
            NotificationManager.error("Please select off time request");
            return;
        }

        const formData = new FormData();
        formData.append("technician_id", technicianDetails.id);
        formData.append("startdate", startTime);
        formData.append("enddate", endTime);
        formData.append("off_time_note", addBreakRequest);
        addTechnicianOffTime(formData).then((res) => {
            if (res.code === 200) {
                console.log("res", res.data.status);
                if (res.data.status == "success") {
                    const newTechnicianOfftime = res.data.data;
                    setTechnicianOfftime(newTechnicianOfftime);
                    setAddBreakRequest("");
                    NotificationManager.success("Off time added successfully");
                }
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert("Something went wrong");
        });
    };


    const [selected, setSelected] = useState([]);

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Delete-Active-bttn me-2" onClick={
                    () => {
                        const deleteId = row.id;
                        handleDelete(deleteId)
                    }
                }><RiDeleteBin6Line /></Button>
            </div>
        );
    }

    const handleDelete = (deleteId) => {
        alert("Are you sure you want to delete this record?");
        const formData = new FormData();
        formData.append("off_time_id", deleteId);
        formData.append("technician_id", technicianDetails.id);
        deleteTechnicianOffTime(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == "1") {
                    const newTechnicianOfftime = technicianOfftime.filter((item) => item.id !== deleteId);
                    setTechnicianOfftime(newTechnicianOfftime);
                }
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }


    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true
    }, {
        dataField: 'startdate',
        text: 'START DATE',
        sort: true,
        formatter: (cell, row) => row.formatted_startdate,
        sortValue: (cell, row) => row.startdate
    }, {
        dataField: 'starttime',
        text: 'START TIME',
        sort: true
    },
    {
        dataField: 'enddate',
        text: 'END DATE',
        sort: true,
        formatter: (cell, row) => row.formatted_enddate,
        sortValue: (cell, row) => row.enddate
    },
    {
        dataField: 'endtime',
        text: 'END TIME',
        sort: true
    },
    {
        dataField: 'off_time_note',
        text: 'OFF TIME REQUEST',
        sort: true
    }, {
        dataField: 'status',
        text: 'STATUS',
        sort: true
    },
    {
        text: 'ACTION',
        isDummy: true,
        formatter: optionFormatter
    }];

    const [state, setState] = useState({
        start: new Date(),
        end: new Date(),
        focusedInput: null,
        timezone: "America/Los_Angeles",
        secondDisplay: false
    });

    const handleSearch = (e) => {
        console.log(e.target);
    }

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));


    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const [timezone, setTimezone] = useState("America/Los_Angeles");
    const [secondDisplay, setSecondDisplay] = useState(false);

    const applyCallback = (startDate, endDate) => {
        console.log("Apply Callback");
        console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
        console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));


        setStart(startDate);
        setEnd(endDate);

        const start_date = startDate.format("YYYY-MM-DD HH:mm:ss");
        const end_date = endDate.format("YYYY-MM-DD HH:mm:ss");

        console.log("start_date", start_date);
        console.log("end_date", end_date);

        setStartTime(start_date);
        setEndTime(end_date);



    };

    const rangeCallback = (index, value) => {
        console.log(index, value);
    };

    const onClick = () => {
        const newStart = moment(start).subtract(3, "days");
        setStart(newStart);
    };

    const renderVanillaPicker = (ranges, local, maxDate) => {
        const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
        const disabled = false;



        return (
            <div>
                {/* <br /> */}
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={applyCallback}
                    rangeCallback={rangeCallback}
                    smartMode
                >
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        style={{ cursor: "pointer" }}
                        disabled={disabled}
                        value={value}
                    />
                </DateTimeRangeContainer>
                {/* <br /> */}
            </div>
        );
    };

    let now = new Date();

    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1st August 18": [
            moment("2018-08-01 00:00:00"),
            moment("2018-08-02 23:59:59")
        ],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };
    let local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };
    let maxDate = moment(end).add(24, "hour");
    let pickersRender = (
        <div>
            {/* <br /> */}
            {renderVanillaPicker(ranges, local, maxDate)}
        </div>
    );

    
    

    return (
        <>

            <div className="card-custom-header">
                <h5 className="tab-content-title mb-3">Add Your Off Times</h5>
            </div>
            <div className="row add-offtime-row mb-4">
                <div className="col-md-4">
                    <Form.Group
                        className="booking-custom-lable"
                        controlId="formBasicEmail"
                    >
                        <div className="row">
                            <div className="col-md-12">
                                {pickersRender}
                            </div>
                        </div>


                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <div className="off-time-detail-btn">

                        <Form.Select aria-label="Default select example" value={addBreakRequest} onChange={
                            (e) => {
                                console.log(e.target.value);
                                setAddBreakRequest(e.target.value);
                            }
                        } >
                            <option value="">Select Off Time Request</option>
                            <option value="Sick Time">Sick Time</option>
                            <option value="Unrequested Absence">Unrequested Absence</option>
                            <option value="Vacation Time">Vacation Time</option>
                            <option value="Unpaid Time Off">Unpaid Time Off</option>
                        </Form.Select>


                    </div>
                </div>
                <div className="col-md-4">
                    <div className="off-time-detail-btn">
                        <button
                            type="button"
                            className="btn bg-green-dark action-btn btn-green"
                            onClick={addBreakTechnician}
                        >
                            Add Break
                        </button>
                    </div>
                </div>
            </div>

            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <SearchBox onSearch={handleSearch} />
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={data} columns={columns} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <NotificationContainer />

        </>
    );



}

export default Informasi;




















