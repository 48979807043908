import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


//add user
export const addUser = (formData) => apiCall(`${API_URL}addUser`, "POST", formData);

//get getuserbycolumn
export const getuserbycolumn = (formData) => apiCall(`${API_URL}getuserbycolumn`, "POST", formData);

//update user
export const updateUser = (formData) => apiCall(`${API_URL}updateuser`, "POST", formData);

//delete user
export const deleteUser = (formData) => apiCall(`${API_URL}deleteuser`, "POST", formData);

//get user booking
export const getUserBooking = (formData) => apiCall(`${API_URL}getUserBooking`, "POST", formData);

//get user booking
export const createkeapsysnsid = (formData) => apiCall(`${API_URL}createkeapsysnsid`, "POST", formData);

//get user booking
export const getKeapdataemail = (formData) => apiCall(`${API_URL}getKeapdataemail`, "POST", formData);

//get getuserbycolumn
export const getusershippingaddress = (formData) => apiCall(`${API_URL}getusershippingaddress`, "POST", formData);

//get getusershippingaddressbyid
export const getusershippingaddressbyid = (formData) => apiCall(`${API_URL}getusershippingaddressbyid`, "POST", formData);

//get deleteusershippingaddressApi
export const deleteusershippingaddressApi = (formData) => apiCall(`${API_URL}deleteusershippingaddress`, "POST", formData);

//get addusershippingaddress
export const addusershippingaddress = (formData) => apiCall(`${API_URL}addusershippingaddress`, "POST", formData);
