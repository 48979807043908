import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Header from '.././camponent/Header/Header';
import Topnavigation from '.././camponent/Topnavigation/Topnavigation';
import Herosection from '.././camponent/Herosection/Herosection';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Button from "react-bootstrap/Button";
import './Dashboard.css';
import Select from "react-select";
import Profile from '.././camponent/Profile/Profile';
import { BsPlusLg } from "react-icons/bs";
import Visa from '../../../Booking/assets/Images/social/visa.webp';
import Mastercard from '../../../Booking/assets/Images/social/mastercard.webp';
import Americanex from '../../../Booking/assets/Images/social/americanex.webp';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Profile2 from "../camponent/Profile/Profile2";
import { usePaymentInputs } from 'react-payment-inputs';
import { LoginContext } from "../../LoginContext";
import { getpaymentMethod } from "../../Api/dashboardApi";
import { deletepaymentmethod } from "../../Api/dashboardApi";
import { getstaffApi } from "../../Api/dashboardApi";
import { cancelbookingbyid } from "../../Api/dashboardApi";
import { getUserBookingfront } from "../../Api/dashboardApi";
import { getbookingstaff } from "../../Api/dashboardApi";
import { createPaymentMethod } from "../../Api/dashboardApi";
import { saverescheduledata } from "../../Api/dashboardApi";
import { getinvoiceApi } from "../../Api/dashboardApi";
import { payinvoiceApi, updatepaymentMethod, getdefaultpayment, checkTimeis24hours, getservertimzoneapi, postreview } from "../../Api/dashboardApi";
import Spinner from 'react-bootstrap/Spinner';
import { Roller } from "react-awesome-spinners";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import { BsStarFill, BsStar } from 'react-icons/bs';
import { NotificationContainer, NotificationManager } from 'react-notifications';


function Dashboard() {
    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [bookings, setBookings] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [staffList, setStaffList] = useState([])

    const [orderid, setOrderId] = useState('');
    const [bookingAmount, setBookingAmount] = useState('');
    const [bookingStaffName, setBookingStaffName] = useState('');
    const [bookingdatetime, setBookingDateTime] = useState('')

    const { getExpiryDateProps } = usePaymentInputs();

    const [cardNumber, setCardNumber] = useState('');
    const [cardExpDate, setCardExpDate] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [fullName, setFullName] = useState('');
    const [addCardValidate, setAddCardValidate] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const [show4, setShow4] = useState(false);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [showText, setShowText] = useState(false);

    const [show6, setShow6] = useState(false);

    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    const [show7, setShow7] = useState(false);

    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);

    const handleClose11 = () => setShow4(false);

    const [lastrecordcount, setLastRecordCount] = useState(1);
    const [singalbookingData, setSingalbookingData] = useState([]);
    const [staffs, setstaffs] = useState([]);
    const [selectstaffs, setSelectstaffs] = useState([]);
    const [loading, setLoading] = useState(false);

    const [bookingdate, setBookingDate] = useState(null);
    const [bookingtime, setBookingTime] = useState(null);
    const [notes, setNotes] = useState(null);
    const [staffData, setStaffData] = useState(null);
    const [UpdateTimeDate, setUpdateTimeDate] = useState(0);

    const [cardNumbererror, setCardNumbererror] = useState(false);
    const [cardCVVerror, setCardCVVerror] = useState(false);
    const [cardExpDateerror, setCardExpDateerror] = useState(false);
    const [fullNamerror, setFullNamerror] = useState(false);
    const [cardExpYearerror, setCardExpYearerror] = useState(false);

    const [defaultpaymentmethod, setDefaultpaymentmethod] = useState(null);
    const [timeshow, setTimeshow] = useState(false);
    const [appicall, setAppicall] = useState(false);

    const [loadingapi, setLoadingapi] = useState(false);


    useEffect(() => {
        if (LoggedInData != null) {
            getUserData(LoggedInData.id)
            getstaff();
        }
    }, [LoggedInData]);


    const getUserData = (uid) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", uid);
        formData.append("lastrecordcount", lastrecordcount);
        getUserBookingfront(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == "1") {
                    const apiResponse = res.data;
                    setBookings([...bookings, ...apiResponse.data]);
                    setLastRecordCount(lastrecordcount);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        if (LoggedInData != null) {
            getUserData(LoggedInData.id)
        }
    }, [lastrecordcount]);


    const getPayments = () => {
        setLoadingapi(true);
        const formData = new FormData();
        formData.append("customer_id", LoggedInData.customer_id);

        getpaymentMethod(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setPaymentMethods(apiResponse.data);
                    getdefaultPaymentMethod();
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        }).finally(() => {
            setLoadingapi(false);
        })
    }

    const getdefaultPaymentMethod = () => {
        const formData = new FormData();
        formData.append("user_id", LoggedInData.id);

        getdefaultpayment(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setDefaultpaymentmethod(apiResponse.data.payment_id);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });
    }


    const getcardtype = (date) => {
        const month = date.slice(0, 2);
        const year = date.slice(2);
        const formattedDate = `${month}/${year}`;
        return formattedDate;
    }

    const deleteCard = (id) => {
        if (window.confirm("Are you sure you want to delete this card?")) {
            const formData = new FormData();
            formData.append("id", id);
            deletepaymentmethod(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == '1') {
                        const apiResponse = res.data;
                        getPayments();

                    }
                    else {
                        console.log("Something went wrong");
                    }

                } else {
                    alert("Something went wrong");
                }
            });
        }

    }

    const getstaff = () => {
        getstaffApi().then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setStaffList(apiResponse.data);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });
    }

    const cancelbooking = () => {
        if (notes == null) {
            alert("Please enter notes");
            return;
        }
        const formData = new FormData();
        formData.append("order_id", orderid);
        formData.append("notes", notes);
        formData.append("booking_date_time", bookingdatetime);
        cancelbookingbyid(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    console.log(apiResponse);
                    window.location.reload();
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        }).finally(() => {
            handleClose();
        })
    }


    const reschedulebooking = (booking) => {
        setSingalbookingData(booking);

        const formData = new FormData();
        formData.append("order_id", booking.order_id);

        getbookingstaff(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setstaffs(apiResponse.data);

                    // const staff_ids = apiResponse.staff_ids;
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });



    }

    const getbookingdate = (date) => {
        if (date != null) {
            const date1 = date.slice(0, 10);
            return date1;
        }
    }

    const getbookingtime = (date) => {
        if (date != null) {
            const time = date.slice(11);
            return time;
        }
    }

    const handleAddCard = () => {
        // if (cardNumber == '' || cardNumber.length != 20 || fullName == '' || cardCVV == '' || cardCVV.length != 3 || cardExpDate == '') {
        //     setAddCardValidate(true)
        // } else {

        if (cardNumber == '' || cardNumber.length != 19) {
            setCardNumbererror(true);
            return;
        } else {
            setCardNumbererror(false);
        }

        if (cardCVV == '' || cardCVV.length != 3) {
            setCardCVVerror(true);
            return;
        } else {
            setCardCVVerror(false);
        }

        if (cardExpDate == '') {
            setCardExpDateerror(true);
            return;
        }

        if (fullName == '') {
            setFullNamerror(true);
            return;
        } else {
            setFullNamerror(false);
        }

        const expDateParts = cardExpDate.split('/');

        const expMonth = parseInt(expDateParts[0]);
        const expYear = parseInt(expDateParts[1]);

        const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1
        const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year

        if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
            // alert("Card expiration date should be in the future.");
            setCardExpYearerror(true);
            return;
        } else {
            setCardExpYearerror(false);
        }

        const name = fullName.split(' ');
        if (name.length < 2) {
            setFullNamerror(true);
            return;
        } else {
            setFullNamerror(false);
        }



        const formData = new FormData();
        formData.append('customer_id', LoggedInData.customer_id);
        formData.append("card_number", cardNumber);
        formData.append("person_name", fullName);
        formData.append("card_cvv", cardCVV);
        formData.append("card_exp", cardExpDate);

        createPaymentMethod(formData).then((res) => {
            if (res.code === 200) {
                alert("credentials added successfully");
                getPayments();
                const apiResponse = res.data.data.id;
                setDefaultpaymentmethod(apiResponse);
                updatepaymentdetails(apiResponse);
            }
            else {

                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        });
        setShow4(false)
        // }
    }



    useEffect(() => {
        //get value from staffList and compare with staffs and set selectstaffs
        if (staffList.length > 0) {
            let temp = [];
            staffList.map((item) => {
                staffs.map((item2) => {
                    if (item.value == item2.staff_ids) {
                        temp.push(item);
                    }
                })
            }
            )
            setSelectstaffs(temp);
        }

        setBookingDate(getbookingdate(singalbookingData.booking_date_time));
        setBookingTime(getbookingtime(singalbookingData.booking_date_time));
        setNotes(singalbookingData.app_notes)

    }, [staffList, staffs]);

    const savereschedule = () => {


        const bookingdatetime = bookingdate + " " + bookingtime;


        const formData = new FormData();
        formData.append("order_id", singalbookingData.order_id);

        if (UpdateTimeDate === 1) {
            formData.append("order_date", bookingdatetime);
            formData.append("app_notes", notes);
        } else {
            formData.append("app_notes", notes);
        }



        const staffIds = [];
        if (staffData != null) {
            for (let i = 0; i < staffData.length; i++) {
                staffIds.push(staffData[i].value);
            }
            formData.append("staff_ids", staffIds);
        }
        else {

            const staffIds = [];
            staffs.map((item) => {
                staffIds.push(item.staff_ids);
            })
            formData.append("staff_ids", staffIds);
        }


        saverescheduledata(formData).then((response) => {
            if (response.code === 200) {
                if (response.data.status === "1") {
                    alert("Booking reschedule successfully");
                    handleClose5();
                } else {
                    alert("Something went wrong");
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const downloadInvoice = (id) => {
        alert("downloadInvoice");
        // const formData = new FormData();
        // formData.append("order_id", id);
        // downloadInvoiceApi(formData).then((res) => {
        //     if (res.code === 200) {
        //         if (res.data.status == '1') {
        //             const apiResponse = res.data;
        //             window.open(apiResponse.data, '_blank');
        //         }
        //         else {
        //             console.log("Something went wrong");
        //         }

        //     } else {
        //         alert("Something went wrong");
        //     }
        // });
    }


    const getformeteddate = (date) => {
        const newDate = new Date(date);
        var day = newDate.getDate();
        day = day < 10 ? '0' + day : day;
        const month = newDate.toLocaleString('default', { month: 'short' });
        const year = newDate.getFullYear();
        var hours = newDate.getHours();
        hours = hours < 10 ? '0' + hours : hours;
        var minutes = newDate.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
        return (
            <div>{formattedDate}</div>
        )
    }

    const [invoiceList, setInvoiceList] = useState([]);


    const onCardNumberChange = (event) => {
        let { value, name } = event.target;
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
    };

    const getinvoice = () => {
        setLoading(true);
        const customer_id = LoggedInData.customer_id;
        getinvoiceApi(customer_id).then((response) => {
            if (response.code === 200) {
                if (response.data.status === "1") {
                    console.log("response:", response.data.data);
                    setInvoiceList(response.data.data);
                    getdefaultPaymentMethod();
                } else {
                    alert("No invoice found");
                }
            } else {
                alert("Something went wrong");
            }

        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const [showModal8, setShowModal] = useState(false);

    const handleClose8 = () => setShowModal(false);
    const handleShow8 = () => setShowModal(true);
    const [invoiceId, setInvoiceId] = useState('');
    const [invoicetotal, setInvoicetotal] = useState('');
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [selectedCard, setSelectedCard] = useState('');

    const getpaymentCards = (id, total) => {
        // setShowModal(true);
        handleShow8();
        setInvoiceId(id);
        setInvoicetotal(total);
        const formData = new FormData();
        formData.append("customer_id", LoggedInData.customer_id);

        getpaymentMethod(formData).then((res) => {
            if (res.code == 200) {
                const paymentMethod = res.data.data;
                setPaymentMethod(paymentMethod);
                if (paymentMethod && paymentMethod.length != 0 && !selectedCard) {
                    setSelectedCard(`cardOption0`);
                    setPaymentMethodId(paymentMethod[0].id);
                    setDefaultpaymentmethod(paymentMethod[0].id);
                }
                getdefaultPaymentMethod();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const payinvoice = () => {
        const formData = new FormData();
        formData.append("payment_method_id", paymentMethodId);
        formData.append("apply_balance", invoicetotal);
        formData.append("invoiceId", invoiceId);

        payinvoiceApi(formData).then((response) => {
            if (response.code === 200) {
                if (response.data.status === "1") {
                    alert("Invoice paid successfully");
                    getinvoice();
                    handleClose8();
                } else {
                    alert("Something went wrong");
                }
            } else {
                alert("Something went wrong");
            }

        }
        ).catch((error) => {
            console.log(error);
        }
        );
    }

    const updatepaymentdetails = (payment_id) => {
        const formData = new FormData();
        formData.append("payment_id", payment_id);
        formData.append("user_id", LoggedInData.id);

        updatepaymentMethod(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == 1) {
                    const apiResponse = res.data;
                    getPayments();
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });

    }

    const checkTimeAndAlert = (timeString) => {
        setAppicall(true);
        const formData = new FormData();
        formData.append("booking_date_time", timeString);

        checkTimeis24hours(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == 1) {
                    const apiResponse = res.data.data;
                    if (apiResponse < 24) {
                        setTimeshow(true);
                    } else {
                        setTimeshow(false);
                    }
                }
                else {
                    console.log("data not found");
                }

            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setAppicall(false);
            handleShow();
        })
    }

    const [defaulttimezone, setDefaulttimezone] = useState('');

    useEffect(() => {
        getservertimzone();
    }, []);
    const getservertimzone = () => {

        getservertimzoneapi().then((res) => {
            if (res.code === 200) {
                if (res.data.status == 1) {
                    const apiResponse = res.data;
                    setDefaulttimezone(apiResponse.data.date);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });
    }

    const isWithin24Hours = async (bookingDateTime) => {

        const bookingDate = new Date(bookingDateTime);
        const currentDate = new Date(defaulttimezone);
        const timeDifference = bookingDate.getTime() - currentDate.getTime();
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference <= 24 && hoursDifference >= 0;
    };

    const [show9, setShow9] = useState(false);

    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);

    const [review_title, setReview_title] = useState('');
    const [review_text, setReview_text] = useState('');
    const [staff_id, setStaff_id] = useState('')

    const [reviewstar, setReviewstar] = useState("");

    const reviewBooking = () => {

        if(review_title === ""){
            NotificationManager.error("Please enter review title");
            return;
        }

        if(reviewstar === ""){
            NotificationManager.error("Please enter star");
            return;
        }

        if(review_text === ""){
            NotificationManager.error("Please enter review text");
            return;
        }


        const formData = new FormData();
        formData.append("review_title", review_title);
        formData.append("rating", reviewstar);
        formData.append("review_text", review_text);
        formData.append("client_id", LoggedInData.id);
        formData.append("reviewer_name", LoggedInData.first_name + " " + LoggedInData.last_name);
        formData.append("order_id", orderid);
        formData.append("staff_id", staff_id);

        postreview(formData).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                handleClose9();
                getUserData(LoggedInData.id);
                NotificationManager.success("Review successfully Submitted")
                // setReviewdata(ApiResponse.data);
                // window.location.href = "/getreviews";
                window.location.reload();
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });

    }


    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='custum-modal-body'>
                    <div className="body-text">
                        <h3 className='mb-3'>Really want to cancel this booking?</h3>
                        <Form.Group className="mb-4" controlId="validationCustom01">
                            <Form.Control as="textarea" rows={3} required type="text" value={notes || ""} onChange={
                                (e) => {
                                    setNotes(e.target.value)
                                }
                            } placeholder=" " />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="validationCustom01">
                            {
                                timeshow && timeshow ? <span className='note-text'>Note : Your appointment is scheduled less than 24 hours away. If you cancel now we will have to charge a $65 cancellation fee</span> : null
                            }
                        </Form.Group>
                    </div>
                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={cancelbooking} >Yes</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose}>No</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* Edit Card modal start here */}
            <Modal show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Card Number</Form.Label>
                                <Form.Control required type="name" placeholder="**** **** **** 9856" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input' />
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>CVV</Form.Label>
                                <Form.Control required type="number" placeholder="  " className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose3} >Save Changes</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose3}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Add Card modal start here */}
            <Modal show={show4} onHide={handleClose11} centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Card Number</Form.Label>
                                <Form.Control
                                    required
                                    type="tel"
                                    className='custom-input'
                                    value={cardNumber}
                                    onChange={onCardNumberChange}
                                    maxLength="19" />
                                {cardNumbererror == true ? <p style={{ color: 'red' }}>card number is required</p> : null}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input'
                                    value={cardExpDate}
                                    {...getExpiryDateProps({ onChange: (e) => setCardExpDate(e.target.value) })} />
                                {cardExpDateerror == true ? <p style={{ color: 'red' }}>please enter card exipry date</p> : ''}
                                {cardExpYearerror == true ? <p style={{ color: 'red' }}>Card expiration date should be in the future.</p> : ''}
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>CVV</Form.Label>
                                <Form.Control required type="number" placeholder="CVV" className='custom-input'
                                    value={cardCVV}
                                    onInput={
                                        (e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                        }
                                    }
                                    onChange={(e) => setCardCVV(e.target.value)} />
                                {cardCVVerror == true ? <p style={{ color: 'red' }}>please enter cvv</p> : ''}
                                {cardCVV.length != 3 ? <p style={{ color: 'red' }}>cvv must have 3 digits</p> : ''}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)} />
                                {fullNamerror == true ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' type="submit" onClick={handleAddCard} >Save</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose11}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Reschedule Booking modal start here */}
            <Modal show={show5} onHide={handleClose5} centered>
                <Modal.Header closeButton>
                    <div>
                        <Modal.Title>Reschedule Booking </Modal.Title>
                        <div className=''>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Current Booking Date:</div>
                                <div className='custom-txt'>
                                    <div><h6>{getbookingdate(singalbookingData.booking_date_time)}</h6></div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Current Booking Time :</div>
                                <div className='custom-txt'>
                                    <div><h6>{getbookingtime(singalbookingData.booking_date_time)}</h6></div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Ammount :</div>
                                <div className='custom-txt'>
                                    <div><h6>{`$${singalbookingData.amount}`}</h6></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="booking-custom-lable mb-4" controlId="formBasicEmail">
                                <Form.Label className=''>Select Staff</Form.Label>
                                <Select
                                    isMulti
                                    defaultValue={selectstaffs}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={staffList}
                                    onChange={(e) => setStaffData(e)}

                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Note</Form.Label>
                                <Form.Control as="textarea" rows={3} required type="text" value={notes || ""} onChange={
                                    (e) => {
                                        setNotes(e.target.value)
                                    }
                                } placeholder=" " />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <div className='d-flex align-items-center'>
                                    <Form.Check type="switch" id="custom-switch" onClick={() => {
                                        setShowText(!showText)

                                        setUpdateTimeDate(1);
                                    }} />
                                    <Form.Label className='custom-lable ms-2 mb-0'>Update Schedule Date & Time</Form.Label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    {showText && <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'> Update Date & Time</Form.Label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Form.Control type="Date" placeholder=" " value={bookingdate} onChange={(e) => setBookingDate(e.target.value)} className='custom-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Control type="time" placeholder="10:30 AM" value={bookingtime} onChange={(e) => setBookingTime(e.target.value)} className='custom-input' />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </>}

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={savereschedule}>Update Appointment</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose5}>Cancel</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Cancel booking modal start here */}
            <Modal show={show6} onHide={handleClose6} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='custom-lable text-center mb-4'>Are you sure you want to cancel this booking?</div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose6}>Yes</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose6}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Delete Card modal start here */}
            <Modal show={show7} onHide={handleClose7} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='custom-lable text-center mb-4'>Are you sure you want to Delete this Card?</div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose7}>Delete</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose7}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Payment Method modal End here */}
            <Modal show={showModal8} className="Sidebar-Modal-end" onHide={handleClose8}>
                <Modal.Header closeButton>
                    <Modal.Title>Fattmerchant Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>

                    {paymentMethod.map((item, index) => {
                        // const cardOptionId = `cardOption${index}`;
                        // const isDefaultCard = defaultpaymentmethod && item.id === defaultpaymentmethod;
                        // const isFirstCard = index === 0;
                        // const isChecked = selectedCard === cardOptionId || (!selectedCard && (isDefaultCard || (!defaultpaymentmethod && isFirstCard)));
                        const isChecked = defaultpaymentmethod ? item.id === defaultpaymentmethod : index === 0;
                        const isCardExpired = checkCardExpiration(item.card_exp) === "Your Card is Expired";
                        return (
                            <div class="form-check mb-3">
                                <div className="d-flex align-items-center">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="cardOption"
                                        id={`cardOption${index}`}
                                        value={`cardOption${index}`}
                                        // checked={defaultpaymentmethod ? item.id === defaultpaymentmethod : index === 0}
                                        defaultChecked={defaultpaymentmethod ? item.id === defaultpaymentmethod : index === 0}
                                        onChange={
                                            (e) => {
                                                setPaymentMethodId(item.id);
                                                setDefaultpaymentmethod(item.id);
                                            }
                                        }
                                        disabled={isCardExpired}
                                    />
                                    <label class="form-check-label payment-card-details" for="flexRadioDefault1">
                                        <div className="card payment-card-details">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div>
                                                        <div className="card-title h5">****-****-****-{
                                                            item.card_last_four || " "
                                                        }</div>
                                                        <p className="card-text">{
                                                            item.nickname || " "
                                                        }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                    <div className='row'>
                        <div className='modal-btn justify-content-end'>
                            <button href="#" className='btn g-btn me-3' type="submit" onClick={payinvoice}>Pay Now</button>
                            <button href="#" className='btn btn-danger' onClick={handleClose8}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Payment Method modal End here */}



            <Modal show={show9} onHide={handleClose9} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Give Feedback!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Label className='custom-lable'>Order ID  :-  {orderid}</Form.Label>
                        </div>
                        <div className='col-md-12'>
                            <Form.Label className='custom-lable'>Booking Amount :-  {`$${bookingAmount}`}</Form.Label>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Label className='custom-lable'>Technician's  :-  {(bookingStaffName).replace(/\s*,\s*/g, ',').replace(/^,|,$/g, '')}</Form.Label>
                        </div>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Booking Date Time  :-  {bookingdatetime}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Review Title</Form.Label>
                                <Form.Control required type="text" onChange={(e) => setReview_title(e.target.value)} placeholder="Enter Review Title" />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Rating</Form.Label>
                                <div className="form-rating">
                                    {
                                        Array.from({ length: 5 }, (_, i) => (
                                            <span key={i} onClick={(e) => setReviewstar(i + 1)} >
                                                {i < reviewstar ? <BsStarFill /> : <BsStar />}
                                            </span>
                                        ))
                                    }
                                </div>
                                {/* <Form.Control required type="number" placeholder="Enter Rating" className='custom-input' /> */}
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Review</Form.Label>
                                <Form.Control required as="textarea" rows={5} onChange={(e) => setReview_text(e.target.value)} placeholder="Enter your review here..." />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' type="submit" onClick={reviewBooking} >Save</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose9}>Cancel</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <div className="App">
                <Header />
                <Topnavigation />
                <Herosection />
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="bg-custom">
                        <div className="dashboard-custom-container hero-bottom">
                            <div className='hero-bottom-margin'>
                                <div className="dashboard-tab-list">
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className='Booking-tab me-4'>Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="Payment" className='Booking-tab me-4' onClick={getPayments}>Payment Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="Transaction" className='Booking-tab' onClick={getinvoice} >Invoices</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="hide-desktop show-mobile">
                                            <Nav.Link eventKey="Profile" className='Booking-tab ms-4'>Profile</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-custom-container dflt-mt-top">
                        <div className='row'>
                            <div className="col-xxl-3 col-xl-4">
                                <Profile />
                            </div>
                            <div className="col-xxl-9 col-xl-8 col-md-12 text-start">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="booking-list">
                                            <nav>
                                                <ul>
                                                    {
                                                        bookings.map((booking, index) => {
                                                            const bookingDateTime = new Date(booking.booking_date_time);
                                                            const currentDateTime = new Date(defaulttimezone);
                                                            return (
                                                                <li key={booking.order_id}>
                                                                    <div className="bookings-service-listed">

                                                                        <div className="booking-list-number">
                                                                            <h1>{index + 1}</h1>
                                                                            <div className="booking-list-content">
                                                                                <div className='order-id'>
                                                                                    <h6>{`Order ID: ${booking.order_id}`}</h6>
                                                                                </div>
                                                                                {/* <div className='order-type' style={{border: `2px solid ${booking.color}`}}> */}
                                                                                <div className='order-type' >
                                                                                    <h4>{booking.title}</h4>
                                                                                </div>
                                                                                <div className='order-text'>
                                                                                    <p>AMOUNT : {`$${booking.amount}`}</p>
                                                                                    <p>Boooking Date Time : {`${booking.booking_date_time}`}</p>
                                                                                </div>
                                                                                <div className='order-text'>
                                                                                    <span>Technician's : <strong>{(booking.staff_name).replace(/\s*,\s*/g, ',').replace(/^,|,$/g, '')}</strong></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="booking-actions">
                                                                            <button href="#" style={{
                                                                                display: 'none',
                                                                            }} className='btn mb-3 Active-bttn' onClick={
                                                                                (e) => {
                                                                                    handleShow5();
                                                                                    reschedulebooking(booking);
                                                                                }
                                                                            } >Reschedule Booking</button>
                                                                            {
                                                                                booking.booking_status === "CC" ? (
                                                                                    <p>Canceled</p>
                                                                                ) : (
                                                                                    <>
                                                                                        {bookingDateTime > currentDateTime && booking.booking_status !== "CO" && (


                                                                                            <button href="#" className="btn Cancel-bttn" onClick={() => {
                                                                                                setOrderId(booking.order_id);
                                                                                                setBookingDateTime(booking.booking_date_time);
                                                                                                checkTimeAndAlert(booking.booking_date_time);
                                                                                            }}>
                                                                                                {
                                                                                                    appicall && orderid === booking.order_id ? (
                                                                                                        <Spinner
                                                                                                            as="span"
                                                                                                            animation="border"
                                                                                                            size="sm"
                                                                                                            role="status"
                                                                                                            aria-hidden="true"
                                                                                                            id={booking.order_id}
                                                                                                        />

                                                                                                    ) : (
                                                                                                        'Cancel Booking'
                                                                                                    )

                                                                                                }
                                                                                            </button>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                booking.booking_status === "CO" && booking.review_status === "0" && (
                                                                                    <button href="#" className="btn Cancel-bttn" onClick={() => {
                                                                                        setOrderId(booking.order_id);
                                                                                        setBookingDateTime(booking.booking_date_time);
                                                                                        setStaff_id(booking.staff_ids);
                                                                                        setBookingStaffName(booking.staff_name);
                                                                                        setBookingAmount(booking.amount);

                                                                                        // reviewBooking(booking);
                                                                                        handleShow9();
                                                                                    }}
                                                                                    >Review</button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                                {
                                                    loading ? (
                                                        <Button className="btn btn-sm add-user-btn btn btn-primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        bookings.length >= 5 ? (
                                                            <button href="#" class="btn btn-sm add-user-btn btn btn-primary" onClick={() => { setLastRecordCount(lastrecordcount + 1); }}>Load more</button>
                                                        ) : null
                                                    )
                                                }

                                            </nav>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Payment">
                                        <div className="payment-settings">
                                            <div className="payment-settings-content">
                                                <div className="payment-settings-content-header d-flex justify-content-beetwin align-items-center">
                                                    <h3>Payment Methods</h3>
                                                    <button href="#" className='btn Add-bttn ms-auto' onClick={handleShow4} ><span><BsPlusLg /></span> Add Card</button>
                                                </div>
                                                {
                                                    loadingapi ? (
                                                        <div className="loading">
                                                            <Roller size={50} color="red" />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="payment-settings-content-body mt-4">
                                                                <nav>
                                                                    <ul>
                                                                        <p className="select-card-text">Select default card</p>
                                                                        {
                                                                            paymentMethods.map((payment, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <input
                                                                                                className="form-check-input me-3"
                                                                                                type="radio"
                                                                                                name="cardOption"
                                                                                                id={`cardOption${index}`}
                                                                                                value={`cardOption${index}`}
                                                                                                checked={defaultpaymentmethod ? payment.id === defaultpaymentmethod : index === 0}
                                                                                                onChange={
                                                                                                    (e) => {
                                                                                                        updatepaymentdetails(payment.id);
                                                                                                    }
                                                                                                }
                                                                                            />
                                                                                            <li className="w-100">
                                                                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                                                    <div className="Payment-list-content">
                                                                                                        <div className='Card-Name'>
                                                                                                            <h4>{payment.person_name}</h4>
                                                                                                        </div>
                                                                                                        <div className='Card-Name'>
                                                                                                            <h4>{payment.card_type}</h4>
                                                                                                        </div>
                                                                                                        <div className='Card-number'>
                                                                                                            <p>{payment.card_last_four}</p>
                                                                                                        </div>
                                                                                                        <div className='Card-number'>
                                                                                                            <p>{checkCardExpiration(payment.card_exp_datetime)}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="booking-actions2" style={{ textAlign: 'right' }}>
                                                                                                        {/* {(!defaultpaymentmethod || payment.id === defaultpaymentmethod) && (
                                                                                                        <Badge bg="info">Default Card</Badge>
                                                                                                    )}
                                                                                                      {(!defaultpaymentmethod || payment.id !== defaultpaymentmethod) && (
                                                                                                            <div className='text-align-right'>
                                                                                                                <button className='btn Delete-bttn denger' onClick={() => deleteCard(payment.id)}>
                                                                                                                    Delete
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        )} */}

                                                                                                        {((!defaultpaymentmethod && index === 0) || payment.id === defaultpaymentmethod) && (
                                                                                                            <Badge bg="info">Default Card</Badge>
                                                                                                        )}
                                                                                                        {
                                                                                                            defaultpaymentmethod === null ? (
                                                                                                                ""
                                                                                                            ) : (

                                                                                                                (!defaultpaymentmethod || payment.id !== defaultpaymentmethod) && (
                                                                                                                    <div className='text-align-right'>
                                                                                                                        <button className='btn Delete-bttn denger' onClick={() => deleteCard(payment.id)}>
                                                                                                                            Delete
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )
                                                                                                        }

                                                                                                        {/* {(!defaultpaymentmethod || payment.id !== defaultpaymentmethod) && index !== 0 && (
                                                                                                            <div className='text-align-right'>
                                                                                                                <button className='btn Delete-bttn denger' onClick={() => deleteCard(payment.id)}>
                                                                                                                    Delete
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        )} */}

                                                                                                        {/* {defaultpaymentmethod ? payment.id === defaultpaymentmethod : index > 0 && (
                                                                                                            <div className='text-align-right'>
                                                                                                                <button href="#" className='btn Delete-bttn denger' onClick={
                                                                                                                    () => {
                                                                                                                        deleteCard(payment.id);
                                                                                                                    }
                                                                                                                } >Delete</button>
                                                                                                            </div>
                                                                                                        )} */}




                                                                                                        <div className='Card-Exp-date w-100'>
                                                                                                            <p>{`Card expires at ${getcardtype(payment.card_exp)}`}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Transaction">
                                        <div className="transaction-History">
                                            <div className="transaction-History-content">
                                                <div className="transaction-History-content-header mb-4">
                                                    <h3>Invoice Details</h3>
                                                </div>
                                                <Table className='Transacrion-Table'>
                                                    <thead>
                                                        <tr>
                                                            <th>DATE</th>
                                                            <th>DETAILS</th>
                                                            <th>AMOUNT</th>
                                                            <th>STATUS</th>
                                                            <th style={{ display: 'none' }}>DOWNLOAD</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            invoiceList && invoiceList.map((invoice) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{getformeteddate(invoice.created_at)}</td>
                                                                        <td>
                                                                            <ul>
                                                                                {invoice.meta.lineItems.map((item) => (
                                                                                    <li key={item.id}>
                                                                                        {item.item} - Quantity: {item.quantity} - Price: ${item.price}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </td>
                                                                        <td className='Price-bold'>{`$${invoice.total}`}</td>
                                                                        {/* <td>{
                                                                            invoice.status == 'PAID' ? <span className='paid'>Paid</span> : <Button className="Enable-Active-bttn"
                                                                                onClick={() => getpaymentCards(invoice.id, invoice.total)}>PAY NOM</Button>
                                                                                
                                                                        }</td> */}
                                                                        <td>
                                                                            {invoice.total !== 0 && (
                                                                                invoice.status === 'DRAFT' || invoice.status === 'PENDING' ? (
                                                                                    <Button
                                                                                        className="Enable-Active-bttn invoice-list-btn"
                                                                                        onClick={() => getpaymentCards(invoice.id, invoice.total)}
                                                                                    >
                                                                                        PAY Now
                                                                                    </Button>
                                                                                ) : (
                                                                                    invoice.status
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td style={{ display: 'none' }}><button className='invoice-download' onClick={
                                                                            () => {
                                                                                downloadInvoice(invoice.id);
                                                                            }
                                                                        }>Invoice {invoice.created_at}</button></td>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                {
                                                    loading ? (
                                                        <Button className="btn btn-sm add-user-btn btn btn-primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        invoiceList ? (
                                                            <button href="#" class="btn btn-sm add-user-btn btn btn-primary" style={{ display: 'none' }}>Load more</button>
                                                        ) : null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Profile" className="hide-desktop show-mobile">
                                        <Profile2 />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            <NotificationContainer />
        </>
    );
}


function checkCardExpiration(card_exp_datetime) {
    const thresholdDays = 90;

    // Get the current date and time
    const now = new Date();
    const expDate = new Date(card_exp_datetime);
    const thresholdDate = new Date(now.getTime() + thresholdDays * 24 * 60 * 60 * 1000);

    if (expDate < now) {
        return "Your Card is Expired";
    } else if (expDate <= thresholdDate) {
        return "Your Card is About to expire";
    } else {
        return "";
    }
}

export default Dashboard;
